import React from "react";
import Slider from "react-slick";
import img1 from "../assets/images/1.png";
import img2 from "../assets/images/2.png";
import img3 from "../assets/images/3.png";
import img4 from "../assets/images/4.png";
import img5 from "../assets/images/5.png";

export default function SliderImages() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    centerMode: true,
    slidesToScroll: 5,
    initialSlide: 0,
    nextArrow: "",
    prevArrow: "",
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          nextArrow: "",
          prevArrow: "",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          nextArrow: "",
          prevArrow: "",
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          dots: true,
          nextArrow: "",
          prevArrow: "",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          nextArrow: "",
          prevArrow: "",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          nextArrow: "",
          prevArrow: "",
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          nextArrow: "",
          prevArrow: "",
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img1} alt="Bouwbedeijf" />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img2} alt="Methorst" />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img3} alt="De Langen" />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img4} alt="VinkBow" />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img5} alt="Weboma" />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img3} alt="De Langen" />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img4} alt="VinkBow" />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={img5} alt="Weboma" />
        </div>
      </Slider>
    </div>
  );
}
