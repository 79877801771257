import React from "react";
import Slider from "react-slick";
import "./SimpleSlider.css";
import Background from "../assets/images/xHome-JPDS-1-1.jpg";
import styled from "styled-components";
import Introduction from "./Introduction";

const MainComponent = styled.div`
  height: 700px;
  background: url(${Background});
  background-repeat: round;
  background-size: cover;
  display: flex;
  align-items: center;
`;

export default function SliderTop(props) {
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    fade: true,
    speed: 500,
    nextArrow: "",
    prevArrow: "",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>
        <MainComponent component="main" className="intro_main">
          <Introduction />
        </MainComponent>

        <MainComponent component="main" className="intro_main">
          <Introduction />
        </MainComponent>

        <MainComponent component="main" className="intro_main">
          <Introduction />
        </MainComponent>
      </Slider>
    </div>
  );
}
