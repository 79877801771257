import HomePage from "./components/HomePage";
import BuyerGuidance from "./components/BuyerGuidance";
import BuildQuality from "./components/BuildQuality";
import AfterCare from "./components/AfterCare";

const AppRoutes = [
  {
    index: true,
    element: <HomePage />,
  },
  {
    path: "/kopersbegeleiding",
    element: <BuyerGuidance />,
  },
  {
    path: "/bouwkwaliteit",
    element: <BuildQuality />,
  },
  {
    path: "/nazorg",
    element: <AfterCare />,
  },
];

export default AppRoutes;
