import React from "react";
import Slider from "react-slick";
import "./SimpleSlider.css";
import Background from "../assets/images/rectangle_7.png";
import Background1 from "../assets/images/rectangle_7_2.png";

export default function SimpleSlider(props) {
  const settings = {
    dots: true,
    appendDots: (dots) => (
      <div>
        <ul className="dots_wrapper"> {dots} </ul>
      </div>
    ),
    fade: true,
    swipeToSlide: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    variableWidth: false,
    centerPadding: 0,
    centerMode: true,
    infinite: true,
    draggable: false,
  };

  const style = {
    width: "100%",
    "@media (max-width: 370px)": {
      width: "100% !important",
      height: "100% !important",
    },
  };

  return (
    <div className="slider">
      <Slider {...settings}>
        <div>
          <img style={style} src={Background} alt="Kopersbegeleiding" />
        </div>
        <div>
          <img style={style} src={Background1} alt="Kopersbegeleiding" />
        </div>
      </Slider>
    </div>
  );
}
