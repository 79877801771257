import React, { useEffect } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Background from "../assets/images/Rectangle_3.png";
import "./Introduction.css";
import { useNavigate } from "react-router-dom";

const StyledComponents = styled.div`
  max-width: 499px;
  margin-top: auto;
  margin-bottom: auto;
`;

export default function BuyerIntoduction() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/#contact");
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: "100%",
        display: "flex",
        alignitems: "center",
      }}
    >
      <Box
        display={"flex"}
        paddingLeft={{ sm: 0, lg: 9 }}
        sx={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "contain", sm: "contain", lg: "auto" },
        }}
      >
        <StyledComponents>
          <Typography
            className="mainText"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "24px", lg: "30px" },
              paddingLeft: { xs: "56px", md: "56px", lg: 0 },
              paddingRight: { xs: "56px", md: "56px" },
              color: "#00008b",
              fontWeight: "500",
              fontFamily: "Poppins",
              marginTop: { xs: "0px" },
            }}
          >
            {t("modules.buyerGuidance")}
          </Typography>
          <Typography
            className="nextText"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "15px",
                lg: "15px",
              },
              paddingLeft: { xs: "56px", md: "56px", lg: 0 },
              paddingRight: { xs: "56px", md: "56px" },
              color: "#494949",
              fontWeight: "400",
              marginTop: { xs: "6px", md: "1.5em", lg: "1.5em" },
              fontFamily: "Poppins",
            }}
          >
            {t("main.buyerGuidance.title")}
          </Typography>
          <Button
            className="requestBtn"
            variant="contained"
            endIcon={<EastIcon />}
            onClick={() => handleButtonClick()}
            sx={{
              marginTop: { xs: 1, sm: 2, md: 4, lg: 4 },
              marginLeft: { xs: 6, sm: 7, md: 7, lg: 0 },
              borderRadius: "27px",
              background: "linear-gradient(90deg, #00008b 0%, #3bc7f1 100%)",
              width: { xs: "150px", sm: "193px", md: "200px ", lg: "247px" },
              height: { xs: "35px", sm: "40px", md: "50px", lg: "55px" },
              fontSize: { xs: "10px", sm: "12px", md: "15px", lg: "16px" },
            }}
          >
            {t("main.request.btnText")}
          </Button>
        </StyledComponents>
      </Box>
    </Container>
  );
}
