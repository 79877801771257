import React, { useEffect, useRef, useState } from "react";
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ClickAwayListener } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../assets/images/huisinfo-logo.png";
import "./NavMenu.css";
import {
  Box,
  Button,
  Divider,
  Fab,
  Fade,
  MenuItem,
  MenuList,
  Popper,
  useScrollTrigger,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    return (
      (document.body.scrollTop = 0), (document.documentElement.scrollTop = 0)
    );
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 60,
          right: 60,
          display: { xs: "none", sm: "block", md: "block", lg: "block" },
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}

export default function NavMenu(props) {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuItemClick = (event, index) => {
    let contact = document.getElementsByClassName("contact_id");
    let module = document.getElementsByClassName("module_id");
    let voorWie = document.getElementsByClassName("voorwie_id");
    let huisinfo = document.getElementsByClassName("huisinfo_id");
    module[0].classList.add("active");
    voorWie[0].classList.remove("active");
    huisinfo[0].classList.remove("active");
    contact[0].classList.remove("active");
    setSelectedIndex(index);
    setAnchorEl(null);
    let url = "/kopersbegeleiding";
    if (index === 2) {
      url = "/bouwkwaliteit";
    } else if (index === 3) {
      url = "/nazorg";
    }
    navigate(url);
    setOpen(false);
  };

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
    setAnchorEl(null);
  };

  const navLinkHandler = (index) => {
    setOpen(false);
    if (location.pathname === "/") {
      let element = document.getElementsByClassName("module_id");
      const offsetTop = element.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    } else {
      let url = "/#modules";
      if (index === 2) {
        url = "/#voorwie";
      } else if (index === 3) {
        url = "/#huisinfo";
      } else if (index === 4) {
        url = "/#contact";
      }
      navigate(url);
    }
  };

  const handleMouseOverMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    if (window !== undefined) {
      let navbarlinks = select("#navbar #scrollto", true);
      const navbarlinksActive = () => {
        let position = window.scrollY + 200;
        setOpen(false);
        navbarlinks.forEach((navbarlink) => {
          if (!navbarlink.hash) return;
          let section = select(navbarlink.hash);
          if (!section) return;
          if (
            position >= section.offsetTop &&
            position <= section.offsetTop + section.offsetHeight
          ) {
            navbarlink.classList.add("active");
          } else {
            navbarlink.classList.remove("active");
          }
        });
      };
      window.addEventListener("load", navbarlinksActive);
      window.addEventListener("scroll", navbarlinksActive);
    }
  }, []);

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  const handleClick = () => {
    let element = document.getElementsByClassName("module_id");
    element[0].classList.remove("active");
    return (
      (document.body.scrollTop = 0), (document.documentElement.scrollTop = 0)
    );
  };

  return (
    <>
      <header
        id="header"
        className="header"
        style={{ position: "fixed", width: "100%", zIndex: "10" }}
      >
        <Navbar
          id="navbar"
          className="navbar-expand-sm navbar-toggleable-sm ng-white bg-white box-shadow"
          container
          light
        >
          <NavbarBrand>
            <Link to="/" rel="noreferrer">
              <img
                className="img_style"
                src={Logo}
                alt={t("general.huisinfo")}
                onClick={handleClick}
                loading="lazy"
              />
            </Link>
          </NavbarBrand>
          <NavbarToggler
            onClick={toggleNavbar}
            style={{ display: !collapsed ? "none" : "" }}
            className="mr-2"
          />
          <CloseIcon
            sx={{
              display: collapsed
                ? { xs: "none", md: "none", lg: "none" }
                : { lg: "none" },
              fontSize: "3em",
              color: "#00008b",
            }}
            onClick={toggleNavbar}
          />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!collapsed}
            navbar
          >
            <Box width={{ sm: "400px", md: "500px", lg: "500px" }}>
              <ul className="navbar-nav flex-grow justify-content-between">
                <Box className="navModule">
                  <Button
                    ref={anchorRef}
                    onClick={() => navLinkHandler(1)}
                    onMouseOver={handleMouseOverMenu}
                    disableRipple
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    id="scrollto"
                    className="text-dark module_id"
                    href="#modules"
                  >
                    {t("general.modules")}
                  </Button>
                  <Divider
                    sx={{
                      borderColor: "#000000",
                      marginBottom: "0 !important",
                      display: {
                        xs: "block",
                        sm: "none",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  />

                  {anchorEl && (
                    <Box
                      className="hiddenBox"
                      id="lock-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                          xl: "none",
                        },
                        "& .MuiMenu-list": {
                          paddingBottom: 0,
                        },
                      }}
                    >
                      <MenuItem
                        sx={{
                          width: "243px",
                          bgcolor: "#ffffff",
                          color: "#494949",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "14px",
                          mb: { xs: 0, md: 0, lg: 1 },
                        }}
                        onClick={(event) => handleMenuItemClick(event, 1)}
                      >
                        {t("modules.buyerGuidance")}
                      </MenuItem>
                      <Divider
                        sx={{
                          borderColor: "#494949",
                          margin: "0 !important",
                          pl: { xs: 0, md: 2, lg: 0 },
                        }}
                        variant="middle"
                      />
                      <MenuItem
                        sx={{
                          width: "243px",
                          bgcolor: "#ffffff",
                          color: "#494949",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "14px",
                          mb: { xs: 0, md: 0, lg: 1 },
                        }}
                        onClick={(event) => handleMenuItemClick(event, 2)}
                      >
                        {t("modules.constructionquality")}
                      </MenuItem>
                      <Divider
                        sx={{
                          borderColor: "#494949",
                          margin: "0 !important",
                          pl: { xs: 0, md: 2, lg: 0 },
                        }}
                        variant="middle"
                      />
                      <MenuItem
                        sx={{
                          width: "243px",
                          bgcolor: "#ffffff",
                          color: "#494949",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "14px",
                          mb: { xs: 0, md: 0, lg: 1 },
                        }}
                        onClick={(event) => handleMenuItemClick(event, 3)}
                      >
                        {t("modules.aftercare")}
                      </MenuItem>
                    </Box>
                  )}
                </Box>

                <Box className="navModule">
                  <Button
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    onClick={() => navLinkHandler(2)}
                    disableRipple
                    id="scrollto"
                    className="text-dark voorwie_id"
                    href="#voorwie"
                  >
                    {t("general.forWhom")}
                  </Button>
                  <Divider
                    sx={{
                      borderColor: "#000000",
                      marginBottom: "0 !important",
                      display: {
                        xs: "block",
                        sm: "none",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  />
                </Box>

                <Box className="navModule">
                  <Button
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    id="scrollto"
                    onClick={() => navLinkHandler(3)}
                    className="text-dark huisinfo_id"
                    href="#huisinfo"
                    disableRipple
                  >
                    {t("general.huisinfo")}
                  </Button>
                  <Divider
                    sx={{
                      borderColor: "#000000",
                      marginBottom: "0 !important",
                      display: {
                        xs: "block",
                        sm: "none",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  />
                </Box>

                <Box className="navModule">
                  <Button
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    disableRipple
                    onClick={() => navLinkHandler(4)}
                    id="scrollto"
                    className="text-dark contact_id"
                    href="#contact"
                  >
                    {t("general.contact")}
                  </Button>
                </Box>
              </ul>
            </Box>

            <Popper
              anchorEl={anchorRef.current}
              open={open}
              sx={{
                width: "243px",
                bgcolor: "#ffffff",
                color: "#494949",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                mb: 1,
                inset: "0px auto auto 80px !important",
                zIndex: 10,
                borderRadius: "4px",
                boxShadow:
                  "rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="lock-menu"
                  className="hiddenMenu"
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                    "& .MuiMenuList-root": {
                      paddingBottom: 0,
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox",
                  }}
                >
                  <MenuItem
                    sx={{
                      width: "243px",
                      bgcolor: "#ffffff",
                      color: "#494949",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      mb: 1,
                    }}
                    onClick={(event) => handleMenuItemClick(event, 1)}
                  >
                    {t("modules.buyerGuidance")}
                  </MenuItem>
                  <Divider
                    sx={{
                      borderColor: "#000000",
                      marginBottom: "0 !important",
                    }}
                    variant="middle"
                  />
                  <MenuItem
                    sx={{
                      width: "243px",
                      bgcolor: "#ffffff",
                      color: "#494949",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      mb: 1,
                    }}
                    onClick={(event) => handleMenuItemClick(event, 2)}
                  >
                    {t("modules.constructionquality")}
                  </MenuItem>
                  <Divider
                    sx={{
                      borderColor: "#000000",
                      marginBottom: "0 !important",
                    }}
                    variant="middle"
                  />
                  <MenuItem
                    sx={{
                      width: "243px",
                      bgcolor: "#ffffff",
                      color: "#494949",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      mb: 1,
                    }}
                    onClick={(event) => handleMenuItemClick(event, 3)}
                  >
                    {t("modules.aftercare")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Popper>
          </Collapse>
        </Navbar>

        <ScrollTop {...props}>
          <Fab
            size="small"
            aria-label="scroll back to top"
            sx={{
              bgcolor: "#00008b",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#00008b",
              },
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </header>
    </>
  );
}
