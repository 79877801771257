import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Container,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "styled-components";
import "./BuyerGuiForWhom.css";
import { useTranslation } from "react-i18next";
import Background from "../assets/images/apple-iphone-smartphone-desk.jpg";
import CheckIcon from "@mui/icons-material/Check";

const MainComponent = styled.div`
  background-color: #fff;
  padding: 2%;
  border-radius: 18px;
`;

const Title = styled.h1`
  font-size: 30px;
  color: #00008b;
  font-weight: 500;
  font-family: Poppins;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const ParaTitle = styled.h4`
  margin: 0;
  color: #494949;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  font-family: Poppins;
`;

const AvatarStyled = styled.div`
  width: 28px;
  height: 28px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  background-color: #e9edf5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function BuildQualityFeatures() {
  const { t } = useTranslation();
  return (
    <>
      <Container maxWidth="xl" sx={{ zIndex: 5 }}>
        <Box padding={{ xs: 0, sm: 5, md: 7 }}>
          <Grid container rowSpacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ImageListItem>
                <img
                  src={Background}
                  alt="Kopersbegeleiding"
                  style={{
                    borderRadius: "25px",
                    maxHeight: "500px",
                    boxShadow: "rgb(255 255 255) 15px 2px 30px 10px",
                    objectFit: "fill",
                  }}
                />
              </ImageListItem>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <MainComponent>
                <Title className="typo_title">
                  {t("modules.constructionquality.topFeatures.title")}
                </Title>
                <List>
                  <ListItem sx={{ px: { xs: 0, sm: 0, md: 2 } }}>
                    <ListItemIcon>
                      <AvatarStyled>
                        <CheckIcon />
                      </AvatarStyled>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <ParaTitle className="typo_title2">
                            {t("modules.constructionquality.topFeatures.1")}
                          </ParaTitle>
                        </React.Fragment>
                      }
                      secondary=""
                    />
                  </ListItem>
                  <ListItem sx={{ px: { xs: 0, sm: 0, md: 2 } }}>
                    <ListItemIcon>
                      <AvatarStyled>
                        <CheckIcon />
                      </AvatarStyled>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <ParaTitle className="typo_title2">
                            {t("modules.constructionquality.topFeatures.2")}
                          </ParaTitle>
                        </React.Fragment>
                      }
                      secondary=""
                    />
                  </ListItem>
                  <ListItem sx={{ px: { xs: 0, sm: 0, md: 2 } }}>
                    <ListItemIcon>
                      <AvatarStyled>
                        <CheckIcon />
                      </AvatarStyled>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <ParaTitle className="typo_title2">
                            {t("modules.constructionquality.topFeatures.3")}
                          </ParaTitle>
                        </React.Fragment>
                      }
                      secondary=""
                    />
                  </ListItem>
                  <ListItem sx={{ px: { xs: 0, sm: 0, md: 2 } }}>
                    <ListItemIcon>
                      <AvatarStyled>
                        <CheckIcon />
                      </AvatarStyled>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <ParaTitle className="typo_title2">
                            {t("modules.constructionquality.topFeatures.4")}
                          </ParaTitle>
                        </React.Fragment>
                      }
                      secondary=""
                    />
                  </ListItem>
                  <ListItem sx={{ px: { xs: 0, sm: 0, md: 2 } }}>
                    <ListItemIcon>
                      <AvatarStyled>
                        <CheckIcon />
                      </AvatarStyled>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <ParaTitle className="typo_title2">
                            {t("modules.constructionquality.topFeatures.5")}
                          </ParaTitle>
                        </React.Fragment>
                      }
                      secondary=""
                    />
                  </ListItem>
                  <ListItem sx={{ px: { xs: 0, sm: 0, md: 2 } }}>
                    <ListItemIcon>
                      <AvatarStyled>
                        <CheckIcon />
                      </AvatarStyled>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <ParaTitle className="typo_title2">
                            {t("modules.constructionquality.topFeatures.6")}
                          </ParaTitle>
                        </React.Fragment>
                      }
                      secondary=""
                    />
                  </ListItem>
                </List>
              </MainComponent>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
