import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import "./BuyerGuiForWhom.css";

const Title = styled.h1`
  font-size: 30px;
  color: #00008b;
  font-weight: 500;
  font-family: Poppins;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const ParaTitle = styled.h4`
  margintop: 8px;
  letter-spacing: 0.00938em;
  color: #494949;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-family: Poppins;
`;

export default function AfterCareInfo() {
  const { t } = useTranslation();
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box padding={{ xs: 0, sm: 5, md: 7 }}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} md={12} lg={12} sx={{ px: 5 }}>
              <Box marginTop={{ md: 0, lg: 10 }}>
                <Box>
                  <Title className="typo_title">{t("modules.aftercare")}</Title>
                  <ParaTitle
                    className="typo_title2"
                    style={{ marginTop: "16px" }}
                  >
                    {t("modules.aftercare.info")}
                  </ParaTitle>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
