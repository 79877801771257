import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Background from "../assets/images/xHome-JPDS-1-1.jpg";
import Background1 from "../assets/images/layer_11.png";
import Background2 from "../assets/images/layer_11_2.png";
import AfterCareIntroduction from "./AfterCareIntroduction";
import AfterCareInfo from "./AfterCareInfo";
import AfterCareAdvantages from "./AfterCareAdvantages";
import AfterCareFeatures from "./AfterCareFeatures";

const MainComponent = styled.div`
  height: 700px;
  background: url(${Background});
  background-repeat: round;
  background-size: cover;
  display: flex;
  align-items: center;
`;

const PositionComponent = styled.div`
  display: block;
  position: absolute;
  max-height: 800px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255, 0.5);
  z-index: 2;
`;

export default function AfterCare() {
  return (
    <>
      <MainComponent component="main" className="intro_main">
        <AfterCareIntroduction />
      </MainComponent>

      <Box
        sx={{
          minHeight: "500px",
          display: "flex",
          my: 2,
          position: "relative",
          borderBottom: {
            xs: "1px solid #49494936",
            sm: "1px solid #49494936",
            md: "none",
            lg: "none",
          },
        }}
      >
        <AfterCareInfo />
      </Box>

      <Box
        sx={{
          backgroundPosition: "left",
          minHeight: "700px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          my: 2,
          borderBottom: {
            xs: "1px solid #49494936",
            sm: "1px solid #49494936",
            md: "none",
            lg: "none",
          },
          display: "flex",
          position: "relative",
          backgroundImage: {
            xs: "none",
            md: "none",
            lg: `url(${Background1})`,
          },
        }}
      >
        <PositionComponent />
        <AfterCareFeatures />
      </Box>

      <Box
        sx={{
          backgroundPosition: "right",
          minHeight: "800px",
          my: 2,
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          display: "flex",
          position: "relative",
          backgroundImage: {
            xs: "none",
            md: "none",
            lg: `url(${Background2})`,
          },
        }}
      >
        <PositionComponent />
        <AfterCareAdvantages />
      </Box>
    </>
  );
}
