import React from "react";
import { Box, Container } from "@mui/material";
import styled from "styled-components";

const FooterWrapper = styled.div`
  padding: 16px;
  background: #3cc6f0;
`;

const Title = styled.h1`
  font-size: 14px;
  line-height: 30px;
  color: #000000;
  font-weight: 600;
  font-family: Poppins;
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <Container maxWidth="xl">
        <Box
          flexDirection={{
            xs: "column-reverse",
            md: "column-reverse",
            lg: "row",
          }}
          sx={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Title>
            © 2022 JP Database Solutions | Bredewater 26, 2715 CA Zoetermeer
          </Title>

          <Title>Privacyverklaring | Algemene voorwaarden</Title>

          <Title>KVK: 70354596 | BTW: NL8582.85.368.B01</Title>
        </Box>
      </Container>
    </FooterWrapper>
  );
}
