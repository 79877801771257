import React from "react";
import {
  Box,
  Button,
  Grid,
  Container,
  Divider,
  ImageListItem,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import CheckIcon from "@mui/icons-material/Check";
import styled from "styled-components";
import KopersbegeleidingLogo from "../assets/images/6.png";
import BouwkwaliteitLogo from "../assets/images/7.png";
import NazorgLogo from "../assets/images/8.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const StyledWrapper = styled.div`
  max-width: 394px;
  min-height: 835px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 16px;
  text-align: center;
  border-radius: 30px;
  background-color: #ffffff;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #00008b;
  font-weight: bold;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  font-family: Poppins;
  text-align: center;
`;

const Boldtitle = styled.h4`
  margin: 0;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #00008b;
  font-size: 15px;
  font-weight: 600;
  font-family: Poppins;
`;

const Paratitle = styled.h6`
  margin: 0;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #00008b;
  font-size: 15px;
  font-weight: 500;
  font-family: Poppins;
  white-space: pre-line;
  text-align: initial;
`;

const AvatarStyled = styled.div`
  width: 28px;
  height: 28px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  background-color: #e9edf5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ModuleDetails() {
  const { t } = useTranslation();
  return (
    <>
      <Container maxWidth="xl" px={2}>
        <Box p={{ xs: 0, sm: 0, lg: 9, md: 0 }}>
          <Grid
            container
            rowSpacing={{ xs: 3, md: 3 }}
            py={{ xs: 4, sm: 4, md: 0, lg: 0 }}
            columnSpacing={{ xs: 2, sm: 3, md: 0, lg: 4 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <StyledWrapper>
                <ImageListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  <img
                    src={KopersbegeleidingLogo}
                    alt={t("modules.buyerGuidance")}
                    loading="lazy"
                    style={{ width: "103px" }}
                  />
                </ImageListItem>

                <Title variant="h4" gutterBottom>
                  {t("modules.buyerGuidance")}
                </Title>

                <Divider variant="middle" />

                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                    px: 3,
                    height: "530px",
                  }}
                >
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Boldtitle noWrap>
                        {t("modules.buyerGuidance.onlineCommunicationPlatform")}
                      </Boldtitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.buyerGuidance.OnlineApplicationOptions")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t(
                          "modules.buyerGuidance.constructionPlanningForBuyers"
                        )}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.buyerGuidance.digitalSignatures")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.buyerGuidance.files.impTopics")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.buyerGuidance.deadlines")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle style={{ whiteSpace: "pre-line" }} noWrap>
                        {t(
                          "modules.buyerGuidance.constructionDrawings.shareText"
                        )}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.buyerGuidance.ownApp")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                </Box>
                <Button
                  variant="contained"
                  component={Link}
                  to={"/kopersbegeleiding"}
                  endIcon={<EastIcon />}
                  sx={{
                    borderRadius: "27px",
                    width: "100%",
                    height: "55px",
                    textTransform: "uppercase",
                    justifyContent: "space-between",
                    px: 3,
                    fontWeight: "bold",
                    background: "transparent",
                    color: "#00008b",
                    border: "2px solid #00008b",
                    ":hover": {
                      background:
                        "linear-gradient(90deg, #3bc7f1 0%, #00008b 100%)",
                      color: "#FFFFFF",
                      border: "none",
                    },
                  }}
                >
                  {t("general.evenMore")}
                </Button>
              </StyledWrapper>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <StyledWrapper>
                <ImageListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  <img
                    src={BouwkwaliteitLogo}
                    alt={t("modules.constructionquality")}
                    loading="lazy"
                    style={{ width: "103px" }}
                  />
                </ImageListItem>

                <Title variant="h4" gutterBottom>
                  {t("modules.constructionquality")}
                </Title>

                <Divider variant="middle" />

                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                    px: 3,
                    height: "530px",
                  }}
                >
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Boldtitle noWrap>
                        {t(
                          "modules.constructionquality.inspectionsDuringConstruction"
                        )}
                      </Boldtitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.constructionquality.qualityAssurance")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.notificationLocationRegistration")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.digitalWorkOrders")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.followUpReports")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.followUpReports")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.constructionquality.forSubcontractors")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                </Box>
                <Button
                  variant="contained"
                  endIcon={<EastIcon />}
                  component={Link}
                  to={"/bouwkwaliteit"}
                  sx={{
                    borderRadius: "27px",
                    color: "#00008b",
                    width: "100%",
                    height: "55px",
                    textTransform: "uppercase",
                    justifyContent: "space-between",
                    px: 3,
                    fontWeight: "bold",
                    background: "transparent",
                    border: "2px solid #00008b",
                    ":hover": {
                      background:
                        "linear-gradient(90deg, #3bc7f1 0%, #00008b 100%)",
                      color: "#FFFFFF",
                      border: "none",
                    },
                  }}
                >
                  {t("general.evenMore")}
                </Button>
              </StyledWrapper>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <StyledWrapper>
                <ImageListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                  }}
                >
                  <img
                    src={NazorgLogo}
                    alt={t("modules.aftercare")}
                    loading="lazy"
                    style={{ width: "103px" }}
                  />
                </ImageListItem>

                <Title variant="h4" gutterBottom>
                  {t("modules.aftercare")}
                </Title>

                <Divider variant="middle" />

                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                    px: 3,
                    height: "530px",
                  }}
                >
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Boldtitle noWrap>
                        {t("modules.aftercare.recordingApp")}
                      </Boldtitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.aftercare.deliveryRecordingApp")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.aftercare.secondSignatureRecordingApp")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.aftercare.buyerPortal")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.notificationLocationRegistration")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.digitalWorkOrders")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.followUpReports")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("general.followUpReports")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                  <Grid container wrap="nowrap" sx={{ mt: 2 }}>
                    <Grid item>
                      <AvatarStyled>
                        <CheckIcon sx={{ color: "#00008b" }} />
                      </AvatarStyled>
                    </Grid>
                    <Grid item xs zeroMinWidth sx={{ display: "flex", px: 2 }}>
                      <Paratitle noWrap>
                        {t("modules.aftercare.subContractors")}
                      </Paratitle>
                    </Grid>
                  </Grid>
                </Box>
                <Button
                  variant="contained"
                  endIcon={<EastIcon />}
                  component={Link}
                  to={"/nazorg"}
                  sx={{
                    borderRadius: "27px",
                    width: "100%",
                    height: "55px",
                    textTransform: "uppercase",
                    justifyContent: "space-between",
                    px: 3,
                    fontWeight: "bold",
                    background: "transparent",
                    color: "#00008b",
                    border: "2px solid #00008b",
                    ":hover": {
                      background:
                        "linear-gradient(90deg, #3bc7f1 0%, #00008b 100%)",
                      color: "#FFFFFF",
                      border: "none",
                    },
                  }}
                >
                  {t("general.evenMore")}
                </Button>
              </StyledWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
