import React from "react";
import { Container } from "@mui/material";
import styled from "styled-components";
import SliderImages from "./SliderImages";
import { useTranslation } from "react-i18next";

const StyledWrapper = styled.div`
  max-height: 555px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 30px;
  color: #00008b;
  font-weight: 500;
  font-family: Poppins;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const ParaTitle = styled.h4`
  margin: 0;
  letter-spacing: 0.00938em;
  color: #494949;
  // width: 567px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-family: Poppins;
  text-align: center;
`;

export default function Customers() {
  const { t } = useTranslation();
  return (
    <Container
      maxWidth="xl"
      sx={{ marginTop: { xs: 5, md: 0, lg: 25 }, overflowX: "hidden" }}
    >
      <StyledWrapper>
        <Title variant="h4">{t("general.customers")}</Title>

        <ParaTitle>{t("modules.customers.info")}</ParaTitle>
      </StyledWrapper>
      <SliderImages />
    </Container>
  );
}
