import React from "react";
import { styled } from "@mui/material/styles";
import Footer from "./Footer";
import NavMenu from "./NavMenu";

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: "76px",
}));

function Layout(props) {
  return (
    <div>
      <NavMenu />
      <MainStyle>{props.children}</MainStyle>
      <Footer />
    </div>
  );
}

export default Layout;
