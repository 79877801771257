import React from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import ModuleDetails from "./ModuleDetails";
import ForWhom from "./ForWhom";
import Huisinfo from "./Huisinfo";
import Customers from "./Customers";
import ContactUs from "./ContactUs";
import Background1 from "../assets/images/layer_11.png";
import Background2 from "../assets/images/layer_11_2.png";
import Background3 from "../assets/images/shape_2_4.png";
import Background4 from "../assets/images/layer_21.png";
import SliderTop from "./SliderTop";

const StyledWrapper = styled.div`
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
  background-image: url(${Background3});
`;

const PositionComponent = styled.div`
  display: block;
  position: absolute;
  max-height: 800px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255, 0.5);
  z-index: 2;
`;

const LastComponent = styled.div`
  height: 100%;
  background: url(${Background4});
  background-repeat: round;
  background-size: cover;
  display: flex;
  align-items: center;
`;

export default function HomePage() {
  return (
    <>
      <Box id="main" style={{ overflowX: "hidden" }}>
        <SliderTop />
      </Box>

      <Box bgcolor={"rgb(25 118 210 / 8%)"} height={1} id="modules">
        <ModuleDetails />
      </Box>

      <Box
        id="voorwie"
        sx={{
          backgroundPosition: "left",
          minHeight: "700px",
          backgroundRepeat: "no-repeat",
          overflowX: "hidden",
          backgroundSize: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          borderBottom: {
            xs: "1px solid #49494936",
            sm: "1px solid #49494936",
            md: "none",
            lg: "none",
          },
          backgroundImage: {
            xs: "none",
            md: "none",
            lg: `url(${Background1})`,
          },
        }}
      >
        <PositionComponent />
        <ForWhom />
      </Box>

      <Box
        id="huisinfo"
        sx={{
          backgroundPosition: "right",
          minHeight: "700px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 2,
          position: "relative",
          backgroundImage: {
            xs: "none",
            md: "none",
            lg: `url(${Background2})`,
          },
        }}
      >
        <PositionComponent />
        <Huisinfo />
      </Box>

      <StyledWrapper>
        <Customers />
      </StyledWrapper>

      <LastComponent id="contact">
        <ContactUs />
      </LastComponent>
    </>
  );
}
