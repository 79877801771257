import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./ContactUs.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BoldTitle = styled.h4`
  margin: 0;
  line-height: 1.235;
  letter-spacing: 0.00735em;
  margin-bottom: 0.35em;
  color: #00008b;
  font-size: 24px;
  font-weight: 500;
  font-family: Poppins;
`;

const ParaTitle = styled.h4`
  margin: 0;
  letter-spacing: 0.00938em;
  color: #494949;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-family: Poppins;
`;

const ContactUsTitle = styled.h4`
  margin: 0;
  letter-spacing: 0.00938em;
  color: #494949;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  font-family: Poppins;
  margin-left: 24px;
  margin-right: 24px;
`;

const AvatarStyled = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  background-color: #494949;
`;

const AvatarStyled2 = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  background-color: #494949;

  &:hover {
    background-color: #fff;
    border: 1px solid #3cc6f0;
  }
`;

export default function ContactUs() {
  const form = useRef();
  const { t } = useTranslation();
  const [inputField, setInputField] = React.useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    return () => {
      setSubmitted(false);
    };
  }, []);

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const { name, email, number, message } = inputField;

  function openMailBox(e) {
    e.preventDefault();
    setSubmitted(true);
    if (!name || !email || !number || !message) {
      return true;
    }
    var link =
      "mailto:" +
      "info@jpds.nl" +
      "?subject=" +
      "Aanvraag demo Huisinfo" +
      "&body=" +
      `${message}`;
    window.open(link, "_blank");
    setSubmitted(false);
    setInputField({
      name: "",
      email: "",
      number: "",
      message: "",
    });
  }

  function openMailContactHandler(e, index) {
    e.preventDefault();
    var link =
      index === 1 ? "mailto:" + "info@jpds.nl" : "mailto:" + "hans@huisinfo.be";
    window.open(link, "_blank");
  }

  function handleClickContactNumber(index) {
    window.location.href =
      index === 1 ? "tel:+31 (0) 79 711 27 00" : "tel:+32 (0) 476 88 36 13";
  }

  return (
    <Container maxWidth="xl" sx={{ p: 2 }}>
      <StyledWrapper>
        <Box
          className="title"
          variant="h4"
          fontSize={{ xs: "22px", md: "30px", lg: "30px" }}
        >
          {t("modules.contact.title")}
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={5}>
            <Box
              className="formbold-main-wrapper"
              padding={{ xs: 0, md: 3, lg: 5 }}
            >
              <div className="formbold-form-wrapper">
                <form ref={form} onSubmit={openMailBox} action="">
                  <Typography className="formbold-mb-5" component={"div"}>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder={t("general.name")}
                      className="formbold-form-input"
                      value={name}
                      onChange={(e) => inputsHandler(e)}
                    />
                    {submitted && !name && (
                      <div className="help-block">
                        {t("modules.contact.name.required")}
                      </div>
                    )}
                  </Typography>

                  <Typography className="formbold-mb-5" component={"div"}>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("general.email")}
                      className="formbold-form-input"
                      value={email}
                      onChange={(e) => inputsHandler(e)}
                    />
                    {submitted && !email && (
                      <div className="help-block">
                        {t("modules.contact.email.required")}
                      </div>
                    )}
                  </Typography>

                  <Typography className="formbold-mb-5" component={"div"}>
                    <input
                      type="number"
                      name="number"
                      id="number"
                      placeholder={t("general.phoneNumber")}
                      className="formbold-form-input"
                      value={number}
                      onChange={(e) => inputsHandler(e)}
                    />
                    {submitted && !number && (
                      <div className="help-block">
                        {t("modules.contact.number.required")}
                      </div>
                    )}
                  </Typography>

                  <Typography className="formbold-mb-5" component={"div"}>
                    <textarea
                      rows="6"
                      name="message"
                      id="message"
                      placeholder={t("general.message")}
                      className="formbold-form-input"
                      value={message}
                      onChange={(e) => inputsHandler(e)}
                    ></textarea>
                    {submitted && !message && (
                      <div className="help-block">
                        {t("modules.contact.message.required")}
                      </div>
                    )}
                  </Typography>

                  <Button
                    variant="contained"
                    type="submit"
                    endIcon={<EastIcon />}
                    sx={{
                      borderRadius: "27px",
                      width: "200px",
                      height: "55px",
                      textTransform: "uppercase",
                      justifyContent: "space-between",
                      px: 3,
                      background: "#fff",
                      color: "#00008b",
                      border: "2px solid #00008b",
                      ":hover": {
                        background:
                          "linear-gradient(90deg, #3bc7f1 0%, #00008b 100%)",
                        color: "#FFFFFF",
                        border: "none",
                      },
                    }}
                  >
                    {t("general.send")}
                  </Button>
                </form>
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={7}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box mt={{ xs: 4, lg: 7 }}>
              <BoldTitle variant="h4" gutterBottom>
                {t("modules.contact.reachUs")}
              </BoldTitle>

              <ParaTitle>{t("modules.contact.visit")}</ParaTitle>

              <Box sx={{ mt: 4, pl: { xs: 0, sm: 0, md: 9, lg: 0 } }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6}>
                    <Box display={"flex"}>
                      <AvatarStyled>
                        <PlaceOutlinedIcon
                          sx={{ color: "#fbfbff", fontSize: "40px" }}
                        />
                      </AvatarStyled>
                      <ContactUsTitle>
                        Bredewater 26 <br />
                        2715 CA Zoetermeer <br />
                        Nederland
                      </ContactUsTitle>
                    </Box>

                    <Box
                      display={"flex"}
                      onClick={() => handleClickContactNumber(1)}
                      className="contact-items"
                    >
                      <AvatarStyled2
                        style={{ marginTop: "0.5em" }}
                        className="button"
                      >
                        <PhoneIphoneOutlinedIcon
                          className="icon"
                          sx={{
                            color: "#fbfbff",
                            fontSize: "40px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "#3cc6f0",
                            },
                          }}
                        />
                      </AvatarStyled2>
                      <ContactUsTitle
                        style={{ marginTop: "24px", cursor: "pointer" }}
                      >
                        +31 (0) 79 711 27 00
                      </ContactUsTitle>
                    </Box>

                    <Box
                      display={"flex"}
                      onClick={(e) => openMailContactHandler(e, 1)}
                      className="contact-items"
                    >
                      <AvatarStyled2
                        style={{ marginTop: "24px" }}
                        className="button"
                      >
                        <EmailOutlinedIcon
                          className="icon"
                          sx={{
                            color: "#fbfbff",
                            fontSize: "40px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "#3cc6f0",
                            },
                          }}
                        />
                      </AvatarStyled2>
                      <ContactUsTitle
                        style={{ marginTop: "40px", cursor: "pointer" }}
                      >
                        info@jpds.nl
                      </ContactUsTitle>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6}>
                    <Box display={"flex"} mt={{ xs: 3, md: 0 }}>
                      <AvatarStyled>
                        <PlaceOutlinedIcon
                          sx={{ color: "#fbfbff", fontSize: "40px" }}
                        />
                      </AvatarStyled>
                      <ContactUsTitle>
                        Ontginningstraat 24 <br />
                        3530 Houthalen-Helchteren <br />
                        België
                      </ContactUsTitle>
                    </Box>

                    <Box
                      display={"flex"}
                      onClick={() => handleClickContactNumber(2)}
                      className="contact-items"
                    >
                      <AvatarStyled2
                        style={{ marginTop: "0.5em" }}
                        className="button"
                      >
                        <PhoneIphoneOutlinedIcon
                          className="icon"
                          sx={{
                            color: "#fbfbff",
                            fontSize: "40px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "#3cc6f0",
                            },
                          }}
                        />
                      </AvatarStyled2>
                      <ContactUsTitle
                        style={{ marginTop: "24px", cursor: "pointer" }}
                      >
                        +32 (0) 476 88 36 13
                      </ContactUsTitle>
                    </Box>

                    <Box
                      display={"flex"}
                      onClick={(e) => openMailContactHandler(e, 2)}
                      className="contact-items"
                    >
                      <AvatarStyled2
                        style={{ marginTop: "24px" }}
                        className="button"
                      >
                        <EmailOutlinedIcon
                          className="icon"
                          sx={{
                            color: "#fbfbff",
                            fontSize: "40px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "#3cc6f0",
                            },
                          }}
                        />
                      </AvatarStyled2>
                      <ContactUsTitle
                        style={{ marginTop: "40px", cursor: "pointer" }}
                      >
                        hans@huisinfo.be
                      </ContactUsTitle>
                    </Box>
                  </Grid>

                  <Box
                    display={"flex"}
                    ml={{ xs: 2, md: 2, lg: 2 }}
                    component={"a"}
                    target="_blank"
                    href="https://www.linkedin.com/company/jp-database-solutions-b.v./"
                  >
                    <AvatarStyled2 style={{ marginTop: "24px" }}>
                      <LinkedInIcon
                        sx={{
                          color: "#fbfbff",
                          fontSize: "40px",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#3cc6f0",
                          },
                        }}
                      />
                    </AvatarStyled2>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </StyledWrapper>
    </Container>
  );
}
