import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Container, ImageListItem } from "@mui/material";
import styled from "styled-components";
import "./BuyerGuiForWhom.css";
import Background from "../assets/images/huisinfo.png";
import { useTranslation } from "react-i18next";

const Title = styled.h1`
  font-size: 30px;
  color: #00008b;
  font-weight: 500;
  font-family: Poppins;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const ParaTitle = styled.h4`
  margin: 0;
  letter-spacing: 0.00938em;
  color: #494949;
  max-width: 567px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-family: Poppins;
  text-align: initial;
  white-space: pre-line;
`;

const MainComponent = styled.div`
  background-color: #fff;
  padding: 2%;
  border-radius: 18px;
`;

export default function Huisinfo() {
  const { t } = useTranslation();
  return (
    <>
      <Container maxWidth="xl" sx={{ zIndex: 5 }}>
        <Box padding={{ xs: 0, sm: 5, md: 7 }}>
          <Grid
            className="last_grid"
            container
            rowSpacing={1}
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid item xs={12} md={12} lg={6}>
              <MainComponent>
                <Title>{t("modules.huisinfo.title")}</Title>

                <ParaTitle style={{ marginTop: "16px" }}>
                  {t("modules.huisinfo.info")}
                </ParaTitle>
              </MainComponent>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <ImageListItem sx={{ maxWidth: "625px" }}>
                <img
                  style={{
                    borderRadius: "25px",
                    maxHeight: "500px",
                    boxShadow: "rgb(255 255 255) 15px 2px 30px 10px",
                  }}
                  src={Background}
                  alt="Kopersbegeleiding"
                />
              </ImageListItem>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
