import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Container, ImageListItem } from "@mui/material";
import styled from "styled-components";
import SimpleSlider from "./SimpleSlider";
import { useTranslation } from "react-i18next";

const Title = styled.h1`
  font-size: 30px;
  color: #00008b;
  font-weight: 500;
  font-family: Poppins;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const ParaTitle = styled.h4`
  margin: 0;
  letter-spacing: 0.00938em;
  color: #494949;
  max-width: 567px;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  font-family: Poppins;
`;

export default function ForWhom() {
  const { t } = useTranslation();
  return (
    <>
      <Container maxWidth="xl" sx={{ zIndex: 5 }}>
        <Box padding={{ xs: 0, sm: 5, md: 7 }}>
          <Grid container rowSpacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{ display: "flex", justifyContent: "center", px: 5 }}
            >
              <ImageListItem sx={{ maxWidth: "625px" }}>
                <SimpleSlider />
              </ImageListItem>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Box>
                  <Title>{t("modules.forWhom.title")}</Title>

                  <ParaTitle
                    style={{ marginTop: "16px" }}
                    dangerouslySetInnerHTML={{
                      __html: t("modules.forWhom.info"),
                    }}
                  />

                  <ParaTitle style={{ marginTop: "32px" }}>
                    {t("modules.forWhom.customerSatisfaction.info")}
                  </ParaTitle>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
